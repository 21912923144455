import { SliderLinkComp } from "@components/PropertyCard/PropertyCard"
import Slider from "@components/elements/Slider/Slider"
import React from "react"
import { Container } from "react-bootstrap"
import "./ImagesSliderModule.scss"
import GGFXImage from "@components/elements/GGFXImage"

const ImagesSliderModule = ({ module, ggfx_results, strapiID }) => {
  if (!module) return null

  const { heading, items } = module

  return (
    <div className="images-slider-module-wrap section-m">
      <Container className="images-slider-module-container">
        <p className="heading">{heading}</p>
        <div className="slider-section">
          <Slider size="lg-alt">
            {items?.map((item) => {

              return (
                <div className="module-card" key={item.id}>
                  <SliderLinkComp
                    className="module-image img-zoom"
                    url={item.url}
                    slider
                    LinkComponent="a"
                    linkProps={{
                      target: "_blank",
                      rel: "noreferrer",
                      href: item.url,
                    }}
                    noClick
                  >
                    <GGFXImage
                      ImageSrc={item?.image}
                      altText={item?.name}
                      imagetransforms={ggfx_results}
                      renderer="srcSet"
                      imagename="page.logo.slider_logo"
                      strapiID={strapiID}
                      lazyLoading
                    />
                    {/* <img
                      src={item?.image?.url}
                      alt="developer-logo"
                      loading="lazy"
                    /> */}
                  </SliderLinkComp>
                </div>
              )
            })}
          </Slider>
        </div>
      </Container>
    </div>
  )
}

export default ImagesSliderModule
