import React from "react"
import "./CareerListing.scss"
import { Container } from "react-bootstrap"
import LocationIcon from "@components/icons/LocationIcon"
import { Link } from "gatsby"
import CTAText from "@components/elements/CTAText/CTAText"
import ArrowUpRightIcon from "@components/icons/ArrowUpRightIcon"
import { careersURL } from "@lib/urls"

const CareerListing = ({ module }) => {
  if (!module) return null

  const { title, careers, cta_text } = module
  return (
    <div className="career-listing-wrap section-p" id="careers-listing">
      <Container className="career-listing-container">
        <h2 className="title">{title}</h2>
        <div className="career-listing-section">
          {careers.map((career) => (
            <div className="career-item" key={career.id}>
              <p className="title">{career.title}</p>
              <div className="sub-section">
                <p className="location">
                  <LocationIcon blue />
                  <span>{career.location}</span>
                </p>
                <Link
                  className="career-link"
                  to={`${careersURL + career.slug}/`}
                >
                  <span>View Details</span>
                  <ArrowUpRightIcon color="orange" />
                </Link>
              </div>
            </div>
          ))}
        </div>
        <CTAText showIcon data={cta_text} />
      </Container>
    </div>
  )
}

export default CareerListing
