/* eslint-disable func-names */
/* eslint-disable no-sequences */
/* eslint-disable no-undef */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import React, { useEffect } from "react"
import "./IFXCalculator.scss"
import { Container } from "react-bootstrap"
import { parseHTML } from "@lib/utils"
import useDeviceMedia from "@hooks/useDeviceMedia"

const IFXCalculator = ({ module }) => {
  const { isMobile } = useDeviceMedia()

  useEffect(() => {
    ;(function (w, d, s, o, f, js, fjs) {
      w.IFX_RATES = o
      w[o] =
        w[o] ||
        function () {
          ;(w[o].q = w[o].q || []).push(arguments)
        }
      ;(js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0])

      js.id = o
      js.src = f
      js.async = 1
      fjs.parentNode.insertBefore(js, fjs)
    })(
      window,
      document,
      "script",
      "ifx_rate_widget",
      "//fx-widget.ifxpayments.com/fx-widget.js"
    )

    ifx_rate_widget("init", {
      elementId: "ifx-widget",

      currencies: ["AED", "EUR", "GBP", "CHF", "JPY", "USD"],

      ppid: "Provident",
    })
  }, [])

  if (!module) return null

  const { title, content } = module

  return (
    <div className="ifx_calculator-wrap section-m" key="ifx-widget">
      <Container className="ifx_calculator-container">
        {!isMobile && <div id="ifx-widget" className="ifx_calculator" />}
        <div className="content-section">
          <h2 className="main-content">{title}</h2>
          {isMobile && <div id="ifx-widget" className="ifx_calculator" />}
          <div className="description">{parseHTML(content?.data?.content)}</div>
        </div>
      </Container>
    </div>
  )
}

export default IFXCalculator
