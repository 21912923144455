import Animation from "@components/elements/Animation"
import GGFXImage from "@components/elements/GGFXImage"
import { parseHTML } from "@lib/utils"
import clsx from "clsx"
import React from "react"
import { Container } from "react-bootstrap"
import "./StaffThoughtBanner.scss"

const StaffThoughtBanner = ({ module, ggfx_results, strapiID }) => {
  const { image, designation, heading, team_name, thought } = module

  
  return (
    <Animation wrapperClassName={clsx("staff-thought-wrap section-m")}>
      <Container className="staff-thought-container">
        <div className="banner-section">
          <div className="bg-img">
            <GGFXImage
              ImageSrc={image}
              altText={team_name}
              imagetransforms={ggfx_results}
              renderer="srcSet"
              imagename="page.image.review_img"
              strapiID={strapiID}
            />
          </div>
          <div className="content-section">
            <p className="heading">{heading}</p>
            <div className="thought">{parseHTML(thought.data.thought)}</div>
            <p className="name">{team_name}</p>
            <p className="designation">{designation}</p>
          </div>
        </div>
      </Container>
    </Animation>
  )
}

export default StaffThoughtBanner
