import CTAText from "@components/elements/CTAText/CTAText"
import { calculateMonthlyPayment } from "@lib/utils"
import { isEmpty } from "lodash"
import React from "react"
import { Container } from "react-bootstrap"
import CurrencyInput from "react-currency-input-field"
import "./PropertyMortgageCalc.scss"
import EnquiryModalWithTrigger from "@components/EnquiryModal/EnquiryModal"
import { ENQUIRY_FORMS } from "@components/forms/utils"
import { useGlobalStore } from "@stores/globalStore"
import { calcPrice } from "@components/CurrencyConverter/CurrConvertPrice"

const PropertyMortgageCalc = ({
  propertyPrice,
  cta_text,
  heading,
  title,
  pageComp,
}) => {
  const { currencyType } = useGlobalStore()
  let pp = parseInt(calcPrice(3000000, currencyType.value, "AED"), 10)
  const [price, setPrice] = React.useState(propertyPrice || pp)
  const [downPaymentPercentage, setDownPaymentPercentage] = React.useState(25)
  const [interestRate, setInterestRate] = React.useState(3.75)
  const [loanPeriod, setLoanPeriod] = React.useState(25)
  const [monthlyPayment, setMonthlyPayment] = React.useState(0)
  const priceset = (value) => {
    if (parseInt(value, 10) <= 0) {
      setPrice(0)
    } else {
      setPrice(value)
    }
  }
  React.useEffect(() => {
      const _monthlyPayment = calculateMonthlyPayment({
        price,
        downPaymentPercentage,
        interestRateYearly: interestRate,
        loanPeriodYearly: loanPeriod,
      })
      setMonthlyPayment(_monthlyPayment.toFixed(2))
  }, [price, downPaymentPercentage, interestRate, loanPeriod])

  return (
    <>
      <div className="property-mortagage-wrap" id="mortgage-calculator">
        {pageComp ? (
          <>
            {heading && <h2 className="title">{heading}</h2>}
            {title && <p className="content">{title}</p>}
          </>
        ) : (
          <>
            <p className="heading">Calculate Mortgage Repayments</p>
            {/* <p className="description">
              Calculate and view the monthly mortgage on this Apartment
            </p> */}
          </>
        )}
        <div className="calculator-section">
          <div className="input-section">
            <p className="label">Total Price ({currencyType?.value})</p>
            {pageComp ?
            <CurrencyInput
            disabled={false}
              className="input-item"
              value={`${
                parseInt(price, 10) >= 0
                  ? price
                  : 0
              }`}
              onValueChange={priceset}
            />
            :
            <CurrencyInput
            disabled={true}
              className="input-item"
              value={`${
                parseInt(price, 10) >= 0
                  ? parseInt(calcPrice(price, currencyType.value, "AED"), 10)
                  : 0
              }`}
              onValueChange={priceset}
            />}
          </div>
          <div className="input-section">
            <p className="label">Down Payment (%)</p>
            <input
              type="number"
              className="input-item"
              value={downPaymentPercentage}
              onChange={(e) => {
                let value = parseInt(e.target.value, 10)

                if (!value || Number.isNaN(value)) {
                  setDownPaymentPercentage(0)
                  return
                }

                if (value >= 0 && value <= 100) {
                  // remove leading zeros
                  value = value.toString().replace(/^0+/, "")
                  setDownPaymentPercentage(value)
                }
              }}
            />
          </div>
          <div className="input-section">
            <p className="label">Interest Rate (%)</p>
            <input
              type="number"
              className="input-item"
              value={interestRate}
              onChange={(e) => {
                let value = parseFloat(e.target.value)

                if (!value || Number.isNaN(value)) {
                  setInterestRate(0)
                  return
                }

                if (value >= 0 && value <= 100) {
                  // remove leading zeros
                  value = value.toString().replace(/^0+/, "")
                  setInterestRate(value)
                }
              }}
            />
          </div>
          <div className="input-section">
            <p className="label">Loan Period Yearly</p>
            <input
              type="number"
              className="input-item"
              value={loanPeriod}
              onChange={(e) => {
                let value = parseInt(e.target.value, 10)

                if (!value || Number.isNaN(value)) {
                  setLoanPeriod(0)
                  return
                }

                if (value >= 0) {
                  // remove leading zeros
                  value = value.toString().replace(/^0+/, "")
                  setLoanPeriod(value)
                }
              }}
            />
          </div>
        </div>
        <div className="result-section">
          <div className="left-side">
            <p className="text">Monthly Payments</p>
            {pageComp ?
             <p className="results">
             {" "}
             {`${currencyType.value} ${monthlyPayment.toLocaleString("en-US")}`}{" "}
             /month
           </p>:
            <p className="results">
              {" "}
              {`${currencyType.value} ${parseInt(
                calcPrice(monthlyPayment, currencyType.value, "AED"),
                10
              ).toLocaleString("en-US")}`}{" "}
              /month
            </p> 
                       }
          </div>
          <div className="right-side">
            <EnquiryModalWithTrigger
              choose_form={ENQUIRY_FORMS.mortagage}
              buttonText="Get a free consultation"
            />
          </div>
        </div>
        {cta_text && <CTAText data={cta_text} showIcon />}
      </div>
      <div className="divider" />
    </>
  )
}

export const MortgageCalculator = ({ module }) => {
  if (isEmpty(module)) return null

  const { cta_text, heading, title } = module

  return (
    <Container className="mortgage-calc-container section-m">
      <PropertyMortgageCalc
        cta_text={cta_text}
        heading={heading}
        title={title}
        pageComp
      />
    </Container>
  )
}

export default PropertyMortgageCalc
