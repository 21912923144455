import React from "react"

const QuoteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      className="quote-icon"
    >
      <g clipPath="url(#clip0_8235_7095)">
        <path
          d="M32.375 24.5L47.25 24.5C47.7141 24.5 48.1592 24.6844 48.4874 25.0126C48.8156 25.3408 49 25.7859 49 26.25V40.25C49 40.7141 48.8156 41.1592 48.4874 41.4874C48.1592 41.8156 47.7141 42 47.25 42H34.125C33.6609 42 33.2158 41.8156 32.8876 41.4874C32.5594 41.1592 32.375 40.7141 32.375 40.25V21C32.375 18.6794 33.2969 16.4538 34.9378 14.8128C36.5788 13.1719 38.8044 12.25 41.125 12.25"
          stroke="url(#paint0_linear_8235_7095)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7 24.5L21.875 24.5C22.3391 24.5 22.7842 24.6844 23.1124 25.0126C23.4406 25.3408 23.625 25.7859 23.625 26.25L23.625 40.25C23.625 40.7141 23.4406 41.1592 23.1124 41.4874C22.7842 41.8156 22.3391 42 21.875 42H8.75C8.28587 42 7.84075 41.8156 7.51256 41.4874C7.18437 41.1592 7 40.7141 7 40.25L7 21C7 18.6794 7.92187 16.4538 9.56282 14.8128C11.2038 13.1719 13.4294 12.25 15.75 12.25"
          stroke="url(#paint1_linear_8235_7095)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_8235_7095"
          x1="40.6875"
          y1="42"
          x2="40.6875"
          y2="12.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#07224B" />
          <stop offset="1" stopColor="#EA6C2E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8235_7095"
          x1="15.3125"
          y1="42"
          x2="15.3125"
          y2="12.25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#07224B" />
          <stop offset="1" stopColor="#EA6C2E" />
        </linearGradient>
        <clipPath id="clip0_8235_7095">
          <rect
            width="56"
            height="56"
            fill="white"
            transform="matrix(-1 0 0 -1 56 56)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default QuoteIcon
