import React from "react"
import "./QuoteModule.scss"
import { Container } from "react-bootstrap"
import { parseHTML } from "@lib/utils"
import QuoteIcon from "@components/icons/QuoteIcon"

const QuoteModule = ({ module }) => {
  return (
    <div className="quote-module-wrap section-m">
      <Container className="quote-module-container">
        <QuoteIcon />
        <div className="quote-text">
          {parseHTML(module?.quote?.data?.quote)}
        </div>
      </Container>
    </div>
  )
}

export default QuoteModule
