import Animation from "@components/elements/Animation"
import useDeviceMedia from "@hooks/useDeviceMedia"
import { parseHTML } from "@lib/utils"
import React from "react"
import { Container } from "react-bootstrap"
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component"
import "react-vertical-timeline-component/style.min.css"
import "./Timeline.scss"

const TimelinePoint = ({ index }) => {
  return <p className="timeline-point">{index} </p>
}

const TimelineNumbered = ({ module }) => {
  const { isTablet } = useDeviceMedia()

  React.useEffect(() => {
    //  select the first item with class "timeline-item-wrap"
    const lastTimelineItem = document.querySelector(
      ".timeline-item-wrap:last-child"
    )

    // get height of the timeline item
    const lastItemHeight = lastTimelineItem?.clientHeight

    // add a css rule to the document head
    const addRule = ((style) => {
      const { sheet } = document.head.appendChild(style)
      return (selector, css) => {
        const propText =
          typeof css === "string"
            ? css
            : Object.keys(css)
                .map((p) => {
                  return `${p}:${p === "content" ? `'${css[p]}'` : css[p]}`
                })
                .join(";")
        sheet.insertRule(`${selector}{${propText}}`, sheet.cssRules.length)
      }
    })(document.createElement("style"))

    // add top value to the timeline item
    addRule(".vertical-timeline::before", {
      top: "24px",
      height: `calc(100% - ${lastItemHeight}px)`,
    })
  }, [])

  if (!module) return null

  const { heading, title, timeline } = module

  return (
    <Animation className="timeline-wrap section-p timeline-numbered">
      <Container className="timeline-container">
        <div className="content-section">
          <p className="heading">{heading}</p>
          <h2 className="title">{title}</h2>
        </div>
        <div className="timeline-section">
          <VerticalTimeline animate={false}>
            {timeline?.map((item, index) => (
              <VerticalTimelineElement
                className="timeline-item-wrap"
                key={item.id}
                icon={<TimelinePoint index={index + 1} />}
              >
                <Animation
                  animateInType={
                    // eslint-disable-next-line no-nested-ternary
                    isTablet ? "right" : index % 2 === 0 ? "left" : "right"
                  }
                  className="timeline-item"
                >
                  {item.image && (
                    <img src={item.image.url} alt="timeline-img" />
                  )}
                  <div className="timeline-item-content">
                    <p className="date">{item.date}</p>
                    <p className="title">{item.title}</p>
                    <div className="description">
                      {parseHTML(item.description?.data?.description)}
                    </div>
                  </div>
                </Animation>
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>
        </div>
      </Container>
    </Animation>
  )
}

export default TimelineNumbered
